import React from "react"
import { Section, Columns } from "../components/Containers"
import { graphql } from "gatsby"
import { Text, Image } from "../components/Core"
import Layout from "../components/layout"
import SEO from "../components/seo"
import { Button } from "../components/Button"

const FeaturedPatientExperiences = ({ pageContext, data }) => {
  const { metaTitle, metaDescription, language, url } = pageContext
  const heading = data.allUniquePagesJson.nodes[0].heading
  const featuredCases = data.allFeaturedPatientExperiencesJson.nodes

  return (
    <Layout language={language} className="featured-patient-experiences">
      <SEO
        title={metaTitle}
        description={metaDescription}
        lang={language}
        pathname={url}
      />

      <Section zeroTop className="hero-container shadow rounded-bottom">
        <Columns sideColumnsSize={2}>
          <div className="column">
            <Text as="h1" text={heading} className="px-40--mobile" />
          </div>
        </Columns>
      </Section>
      <Section>
        <Columns sideColumnsSize={2}>
          <div className="column cases-container px-40--mobile">
            {featuredCases.map(item => (
              <div key={item.caseName}>
                <Text as="h5" text={item.caseName} />
                <div className="case-image-container">
                  <div className="relative">
                    <Image
                      publicId={item.beforeAfterSection.beforeImage}
                      className="case-image"
                    />
                    <div className="text-over-image">
                      <Text as="p" text="Before" />
                    </div>
                  </div>
                  <div className="relative">
                    <Image
                      publicId={item.beforeAfterSection.afterImage}
                      className="case-image"
                    />
                    <div className="text-over-image">
                      <Text as="p" text="After" />
                    </div>
                  </div>
                </div>
                <Button
                  href={`/${item.title}`}
                  buttonText="VIEW CASE"
                  minimal
                  destination="internal"
                  className="mt-2 view-case-button"
                />
              </div>
            ))}
          </div>
        </Columns>
      </Section>
    </Layout>
  )
}

export const pageQuery = graphql`
  query MyQuery {
    allUniquePagesJson(
      filter: { title: { eq: "featured-patient-experiences/" } }
    ) {
      nodes {
        title
        heading
      }
    }
    allFeaturedPatientExperiencesJson {
      nodes {
        title
        caseName
        beforeAfterSection {
          beforeImage
          afterImage
        }
      }
    }
  }
`
export default FeaturedPatientExperiences
